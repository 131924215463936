import '@/bootstrap';
import { Message } from 'element-ui';
import store from '../store';
import router from '../router';
import { isLogged, removeToken } from '@/utils/auth';

; // 'application/json;charset=utf-8',

// Create axios instance
const service = window.axios.create({
  baseURL: process.env.VUE_APP_API_URL+'/user',
  timeout: 1000*60*5, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  config => {
    const token = isLogged();
    if (token) {
      console.log("Authorized"); // for debug
      config.headers['Authorization'] =  isLogged(); // Set JWT token
    }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    console.log(error.response.status)
    try {
      if( error.response.status === 401){
        console.error("Unauthorized")
        store.dispatch('LogOut');
        router.push('/login');
      }else{
        let message = error.message;
        console.log(error);
        if (error.response.data && error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data && error.response.data.error) {
          message = error.response.data.error;
        }
        Message({
          message: message,
          type: 'error',
          duration: 5 * 1000,
        });
      }

    }catch (e) {
      console.log(e);
     let message ="Something went wrong please try again!!"
      Message({
        message: message,
        type: 'error',
        duration: 5 * 1000,
      });
    }




    return Promise.reject(error);
  }
);

export default service;
