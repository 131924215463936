/**
|--------------------------------------------------------------------------
| Vue Component : index
|--------------------------------------------------------------------------
|
| Usage : This Component used for
| Created By rahul on (24 Feb 2022 at 12:18 PM)
|
*/
<template>
  <div class="page-wrap account-pages" :class="page">
  <header class="page-header account-page-header">
    <div class="container">
      <div class="row">
        <div class="col-md-3 ">
          <router-link to="/home" class="main-logo"><img src="images/logo.svg" alt=""></router-link>
        </div>
      </div>
    </div>
  </header>
  <div class="page-wrap">
    <div class="page-content login-page">
      <div class="form-holder">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <MainView></MainView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>

<script>
import FooterPage from './components/FooterView'
import HeaderPage from './components/HeaderView'
import MainView from './components/MainView'

export default {
  components:{FooterPage,HeaderPage,MainView},
  name: "index",
  computed : {
    page : function(){ return this.$store.getters.pageClass},
  },
}
</script>

<style lang="css" scoped>
@import "~@/styles/style.css";

</style>