/**
 |--------------------------------------------------------------------------
 | File Name : common.js
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for
 | Created By rahul on (21 Mar 2022 at 8:18 AM)
 |
 */

const state = {
    page: null,
    lastPath: [],
    routerHistory: [],

};

const getters = {
    pageClass: state => state.page,
    lastPath: state => state.lastPath,
    previousRoute: (state) => {
        const historyLen = state.routerHistory.length;
        if (historyLen == 0) return null;
        return state.routerHistory[historyLen - 1];
    },
};

const actions = {
    async setPage({dispatch}, page) {
        this.commit('setPageClass', page)
    },
    async setLastPath({dispatch}, lastPath) {
        this.commit('setLastPath', lastPath)
    },
};
const mutations = {
    setPageClass(state, page){
        state.page = page
    },
    setLastPath(state, lastPath){
        state.lastPath = lastPath
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};
