import request from '@/utils/request';

export function login(query) {
  return request({
    url: '/login',
    method: 'post',
    data:query,
  });

}
export function register(query) {
  return request({
    url: '/register',
    method: 'post',
    data:query,
  });
}

export function forgotPassword(query) {
  return request({
    url: '/forgot-password ',
    method: 'post',
    data:query,
  });
}

export function resetPassword(query) {
  return request({
    url: '/reset-password ',
    method: 'post',
    data:query,
  });
}

export function profile(query) {
  return request({
    url: '/profile',
    method: 'post',
    data:query,
  });
}

export function updateProfile(query) {
  return request({
    url: '/update-profile',
    method: 'post',
    data:query,
  });
}



