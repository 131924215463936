export default {
  route: {
    home: 'Home',
    about: 'About',
    contact: 'Contact',
    vendors: 'Vendors',
    login: 'Login',
    logout: 'Logout',
    'terms-and-conditions': 'Terms And Conditions',

  },

  login: {
    title: 'Login Form',
    logIn: 'Log in',
    logOut: 'Log out',
    username: 'Username',
    password: 'Password',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !',
    email: 'Email',
  },

  user: {
    'role': 'Role',
    'password': 'Password',
    'confirmPassword': 'Confirm password',
    'name': 'Name',
    'email': 'Email',
  },


};
