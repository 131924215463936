import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layout */
import Layout from '@/layout';
import LoginLayout from '@/layout/login';


Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/index'),
        name: 'Home',
        meta: { title: 'Home', icon: 'home', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import('@/views/home/index'),
        name: 'Home',
        meta: { title: 'Home', icon: 'home', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'vendors',
    children: [
      {
        path: 'vendors',
        component: () => import('@/views/vendor/index'),
        name: 'Vendors',
        meta: { title: 'Vendors', icon: 'Vendors', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'vendor-services',
    children: [
      {
        path: 'vendor-services/:id',
        component: () => import('@/views/checkout/services'),
        name: 'Vendors',
        meta: { title: 'Vendor Services', icon: 'Vendor Services', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'service-slots',
    children: [
      {
        path: 'service-slots/:vendor_location_id',
        component: () => import('@/views/checkout/slots'),
        name: 'Slots',
        meta: { title: 'Service slots', icon: 'Service slots', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'confirm-payment',
    children: [
      {
        path: 'confirm-payment',
        component: () => import('@/views/checkout/payment-confirm'),
        name: 'Confirm Payment',
        meta: { title: 'Confirm Payment', icon: 'Confirm Payment', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'success-payment',
    children: [
      {
        path: 'success-payment',
        component: () => import('@/views/checkout/payment-success'),
        name: 'Confirm Payment',
        meta: { title: 'Success Payment', icon: 'Success Payment', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'payment-failed',
    children: [
      {
        path: 'payment-failed',
        component: () => import('@/views/checkout/payment-failed'),
        name: 'Payment Filed',
        meta: { title: 'Success Payment', icon: 'Payment Failed', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'contact',
    children: [
      {
        path: 'contact',
        component: () => import('@/views/contact/index'),
        name: 'Contact',
        meta: { title: 'Contact', icon: 'Contact', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'about',
    children: [
      {
        path: 'about',
        component: () => import('@/views/about/index'),
        name: 'About',
        meta: { title: 'About', icon: 'About', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'register',
    children: [
      {
        path: 'register',
        component: () => import('@/views/auth/register'),
        name: 'Register',
        meta: { title: 'Register', icon: 'Register', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: '404',
    children: [
      {
        path: '404',
        component: () => import('@/views/common/404'),
        name: '404',
        meta: { title: '404', icon: '404', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'terms-and-conditions',
    children: [
      {
        path: 'terms-and-conditions',
        component: () => import('@/views/common/terms-and-conditions'),
        name: 'terms-and-conditions',
        meta: { title: 'Terms and Conditions', icon: 'terms-and-conditions', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'delivery-procedure',
    children: [
      {
        path: 'delivery-procedure',
        component: () => import('@/views/common/404'),
        name: 'delivery-procedure',
        meta: { title: 'Delivery Procedure', icon: 'delivery-procedure', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'refund-policy',
    children: [
      {
        path: 'refund-policy',
        component: () => import('@/views/common/404'),
        name: 'refund-policy',
        meta: { title: 'Refund Policy', icon: 'refund-policy', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'booking-details',
    children: [
      {
        path: 'booking-details/:id',
        component: () => import('@/views/settings/booked-details'),
        name: 'booking-details',
        meta: { title: 'Booking Details', icon: 'booking-details', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'my-bookings',
    children: [
      {
        path: 'my-bookings',
        component: () => import('@/views/settings/my-bookings'),
        name: 'my-bookings',
        meta: { title: 'My Bookings', icon: 'my-bookings', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'my-profile',
    children: [
      {
        path: 'my-profile',
        component: () => import('@/views/settings/my-profile'),
        name: 'my-profile',
        meta: { title: 'My Profile', icon: 'my-profile', noCache: false },
      },
    ],
  },



  //  Login Layout

  {
    path: '',
    component: LoginLayout,
    redirect: 'login',
    children: [
      {
        path: 'login',
        component: () => import('@/views/auth/login'),
        name: 'Login',
        meta: { title: 'Login', icon: 'Login', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: LoginLayout,
    redirect: 'forgot-password',
    children: [
      {
        path: 'forgot-password',
        component: () => import('@/views/auth/forgot-password'),
        name: 'Forgot Password',
        meta: { title: 'Forgot Password', icon: 'Forgot Password', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: LoginLayout,
    redirect: 'reset-password',
    children: [
      {
        path: 'reset-password',
        component: () => import('@/views/auth/reset-password'),
        name: 'Reset Password',
        meta: { title: 'Reset Password', icon: 'Reset Password', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: LoginLayout,
    redirect: 'confirm-otp',
    children: [
      {
        path: 'confirm-otp',
        component: () => import('@/views/auth/otp-confirm'),
        name: 'Confirm Payment',
        meta: { title: 'Confirm Payment', icon: 'Confirm Payment', noCache: false },
      },
    ],
  },
]

const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.MIX_LARAVUE_PATH,
  routes: routes,
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}


export default router;

